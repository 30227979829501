<template>
  <div class="space-y-4">
    <asom-card>
      <form>
        <div class="gap-20 py-5 bg-white rounded-lg overflow-hidden flex ">
          <div v-for="(item, index) in psmBalanceModels" :key="index" class="w-72">
            <asom-alert v-if="error" variant="error" :error-message="error" />
            <p class="pb-2 font-sans font-semibold text-gray-500">
              {{ item.stationPSMName }}
            </p>
            <div class="max-w-sm relative rounded-lg bg-gray-100 px-6 py-5 shadow items-center">
              <div class="p-2 h-10 w-10 bg-button rounded-md">
                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="white">
                  <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2"
                    d="M12 8c-1.657 0-3 .895-3 2s1.343 2 3 2 3 .895 3 2-1.343 2-3 2m0-8c1.11 0 2.08.402 2.599 1M12 8V7m0 1v8m0 0v1m0-1c-1.11 0-2.08-.402-2.599-1M21 12a9 9 0 11-18 0 9 9 0 0118 0z" />
                </svg>
              </div>
              <div class="pt-4">
                <p class="font-sans font-bold text-gray-500 text-3xl">
                  {{ formatCurrency(parseFloat(item.psmCurrentShiftAmount)) }}
                </p>
                <p class="font-sans font-semibold text-gray-500">
                  in Current Shift
                </p>
              </div>
              <!-- <div class="pt-6">
                <div>
                  <span class="font-sans text-button">{{ formatCurrency(item.psmInFlowAmount)}}</span>    
                  <span class="font-sans text-gray-500"> Float Adjustment (+)</span>
                </div>
                <div>
                  <span class="font-sans text-button">{{ formatCurrency(item.psmOutFlowAmount)}}</span>      
                  <span class="font-sans text-gray-500"> Float Adjustment (-)</span>
                </div>
                <div>
                  <span class="font-sans text-button">{{ formatCurrency(item.currentBalance)}}</span>      
                  <span class="font-sans text-gray-500"> Net Float Adjustment</span>
                </div>
              </div> -->
            </div>

            <asom-form-field class="col-span-2 pt-8" label="Supporting documents">
              <asom-upload-file-list
                :files="item.attachments"
                disabled-remove
              />
            </asom-form-field>
          </div>
        </div>
      </form>
    </asom-card>
    <div class="pt-6">
      <div>PSM Manual Record</div>
      <div class="p-4">Current Shift: {{ shift }}</div>
      <asom-client-table :columns="[
        'transactionNo',
        'createdBy',
        'dateCreated',
        'timeCreated',
        'line',
        'station',
        'rosterDws',
      ]"
      :data="getTableData"
      :sortableColumns="[
        'transactionNo',
        'createdBy',
        'dateCreated',
        'timeCreated',
        'line',
        'station',
        'rosterDws',
      ]" 
      :filterable="false" 
      :pagination="false"
      >
        <template v-slot:dateCreated="scopedSlots">
          <span>{{ displayUtcDate(scopedSlots.data) }}</span>
        </template>
        <template v-slot:timeCreated="scopedSlots">
          <span>{{ displayUtcTime(get(scopedSlots.rowData, "dateCreated")) }}</span>
        </template>
        <template v-slot:header_transactionNo>Transaction No</template>
        <template v-slot:transactionNo="scopedSlots">
          <div class="flex flex-row">
            <div class="flex pl-2">
              <asom-link @click="navigateToView(scopedSlots.rowData)">{{ scopedSlots.data }}
              </asom-link>
            </div>
          </div>
        </template>
      </asom-client-table>
    </div>
  </div>
</template>

<script>
import {required } from "@vuelidate/validators";
import inputStates from "@/mixins/inputStates";
import { useVuelidate } from "@vuelidate/core";
import { mapGetters } from "vuex";
import get from "lodash.get";
import sortBy from "lodash.sortby";
import { getShiftEndInfo } from "@/services/cashManagement.service";
import { formatCurrency } from "../../../../helpers/numbers";
import { displayUtcDate, displayUtcTime } from "@/helpers/dateTimeHelpers";
import { ATTACHMENT_CATEGORIES } from "@/constants/APIEnums/attachmentEnums";

export default {
  name: "MRShiftEndSummaryDetail",
  mixins: [inputStates],
  setup: () => ({ v$: useVuelidate() }),
  data() {
    return {
      psmLogs: [],
      activityLogs: [],
      formData: {
        cashTotal: [],
        files: [],
      },
      psmBalanceModels: [],
      error: null,
    };
  },
  validations() {
    return {
      formData: {
        cashTotal: { required },
        files: { required },
      },
    };
  },
  computed: {
    ...mapGetters({
      pscId: "cashManagement/stationCashAccounts/pscId",
      shift: "currentRoster/currentRosterShift",
      rosterPlanDate: "currentRoster/currentRosterPlanDate",
      rosterDws: "currentRoster/currentRosterShift",
    }),
    getTableData() {
      let logs = sortBy(this.activityLogs, "dateCreated");
      return logs.reverse();
    },
    psmLogIds() {
      return this.psmLogs.map(({ transactionId }) => transactionId);
    },
    attachmentCategories() {
      return ATTACHMENT_CATEGORIES;
    },
  },
  mounted() {
    this.getShiftInfo();
  },
  watch: {
    pscId(newId, oldId) {
      if (newId !== oldId) this.getShiftInfo();
    },
  },
  methods: {
    get,
    displayUtcDate,
    displayUtcTime,
    formatCurrency,
    navigateToView(selectedTransaction) {
      this.$router.push({
        name: "View Manual Record Transaction",
        params: selectedTransaction,
      });
    },
    async getShiftInfo() {
      await this.$store.dispatch("cashManagement/loadStationCashAccounts");
      const route = this.$route.query;
      if (route.id) {
        const res = await getShiftEndInfo(route.id);
        console.log('res----', res.payload);
        if (res.success) {
          this.psmBalanceModels = res.payload.data.psmShiftEndAmountOfVariedPSMViewModels;
          const logs = res.payload.psmTransactions;
          this.activityLogs = logs.map(r => {
            return {
              createdBy: r.createdBy,
              createdById: r.createdById,
              dateCreated:  r.dateCreated,
              line: r.line,
              lineId: r.lineId,
              rosterDws:  r.rosterDws,
              station: r.station,
              stationId: r.stationId,
              transactionId: r.transactionId,
              transactionNo: r.transactionNo,
            }
          }) 
        } else {
          this.error = res.payload;
          this.$scrollTop();
          return;
        }
      }
    },
  },
};
</script>
<style scoped>
.AsomUploadFileList__File {
  @apply w-full space-x-4 justify-start px-6 py-2 border-2 rounded-md;
}

.AsomUploadFileList__FileName {
  @apply flex-1 font-bold;
}
</style>